<script>
import { VclCode } from 'vue-content-loading';
import { mapState } from "vuex";

import TimeAgo from 'vue2-timeago';

import CopyableText from "@/components/CopyableText";

export default {
  components: {
    VclCode,
    CopyableText,
    //TimeAgo
  },
  props: {

  },
  computed: {
    ...mapState({
      username: 'username'
    })
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/banmanager/dashboard/pending', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.banlists = data.banlists;
          this.ready = true;
          this.$nextTick(function() {

          });
        })
        .catch(error => {
          this.handleError(error);
        });
    }
  },
  created() {
    this.getData()
    this.timer = setInterval(() => {this.getData();}, 1000 * 30);
  },
  destroyed() {
    if(this.timer) clearInterval(this.timer);
  },
  data() {
    return {
      ready: false,
      error: false,
      banlists: [],
      timer: null
    }
  }
};
</script>

<template>
  <div v-if="ready">
    <div class="card card-body">
      <h4>Pending Verifications</h4>
      <div class="table-responsive mb-0">
        <table class="table align-middle table-nowrap">
          <thead class="table-dark">
          <tr>
            <th class="align-middle">Id</th>
            <th class="align-middle">Name</th>
            <th class="align-middle">Creation Date</th>
            <th class="align-middle">Ban Count</th>
            <th class="align-middle">Verification Requested</th>
            <th class="align-middle"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="banlist in banlists" :key="banlist.id">
            <td class="align-middle">
              <CopyableText class="text-code" :text="banlist.id"/>
            </td>
            <td class="align-middle">
              <span class="h5 mb-0">
                {{ banlist.name }}
              </span>
            </td>
            <td class="align-middle">
              <div class="h5 mb-0">
                {{ $d(parseDate(banlist.created_at), 'datetime', 'de') }}
              </div>
            </td>
            <td class="align-middle">
              <span class="h5 mb-0">
                {{ $n(banlist.count, {locale: 'de'}) }}
              </span>
            </td>
            <td class="align-middle">
              <div class="h5 mb-0">
                {{ $d(parseDate(banlist.verification.requested_at), 'datetime', 'de') }}
              </div>
            </td>
            <td>
              <router-link :to="{name: 'banmanager_view', params: {banlist_id: banlist.id}}" >
                <button class="btn btn-pill btn-primary btn-rounded align-middle">
                  Open
                </button>
              </router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div v-else-if="error">
    <div class="row">
      <div class="col-lg-12 justify-content-start">
        <div class="card border border-danger">
          <div class="card-header bg-transparent border-danger">
            <h5 class="my-0 text-danger">
              <i class="far fa-exclamation-circle mr-3"></i> {{ $t('error.server.generic.title') }}
            </h5>
          </div>
          <div class="card-body">
            <h5 class="card-title mt-0">
              {{ $t('dashboard.error.info') }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 justify-content-start">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <vcl-code :height="90"></vcl-code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
